import React from 'react';
import Layout from '../components/Layout';
import DrawerButton from '../components/DrawerButton';

export default function Inscripcion() {
  return (
    <Layout>
      <section id="footer">
        <div className="inner">
          <h2 className="major">Sé parte de Oxford House Ushuaia</h2>
          <p>
            Por favor, complete con sus datos para iniciar el proceso de
            inscripción.
          </p>
          <form method="post" action="https://formspree.io/xqkpnndl">
            <div className="fields">
              <div className="field">
                <label htmlFor="name">Nombre completo</label>
                <input type="text" name="name" id="name" />
              </div>
              <div className="field">
                <label htmlFor="name">DNI</label>
                <input type="text" name="dni" id="dni" />
              </div>
              <div className="field">
                <label htmlFor="email">Email</label>
                <input type="email" name="_replyTo" id="email" />
              </div>
              <div className="field">
                <label htmlFor="email">Curso deseado</label>
                <input type="text" name="curso" id="curso" />
              </div>
              <div className="field">
                <label htmlFor="message">Mensaje (opcional)</label>
                <textarea name="message" id="message" rows="3"></textarea>
              </div>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Enviar inscripción" />
              </li>
            </ul>
          </form>
        </div>
      </section>
      <DrawerButton />
    </Layout>
  );
}
